import * as React from "react";
import "../App.css";
import { Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";

function HR() {
  return (
    <div className="HR">
      <Box
        sx={{
          maxWidth: 960,
          margin: "auto",
          textAlign: "left",
        }}
      >
        <Typography variant="h2" color="primary" sx={{ textAlign: "center" }}>
          Gulf-Pro Services Business Conduct
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Our Commitment to Human Rights
        </Typography>
        <Typography variant="h4">People Come First</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          At Gulf-Pro, our respect for human rights begins with our commitment
          to treating everyone with dignity and respect. But it doesn’t end
          there.
          <br />
          <br /> We believe in empowerment and connecting
          people globally— and that business can and should be a force
          for good. Achieving that takes innovation, hard work, and a focus on
          serving others. <br />
          <br />
          It also means leading with our values. Our human rights policy governs
          how we treat everyone—from our customers and teams to our business
          partners and people at every level of our supply chain. <br />
          <br />
          With humility, optimism, and an abiding faith in people, we’re
          committed to respecting the human rights of everyone whose lives we
          touch.
        </Typography>
        <Typography variant="h4">Our Commitment to Human Rights</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          We work every day to provide quality products and
          services; available to our customers in a way that respects their
          human rights. With dialogue, and a belief in the power of engagement,
          we try to find the solution that best serves our customers, their
          ability to express themselves, and their access to reliable
          information and helpful service.
        </Typography>
        <Typography variant="h4">How We Treat People</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          We’ve always said Gulf-Pro’s core is our people. That’s why we’re
          committed to respecting the human rights of everyone whose lives we
          touch—including our employees, suppliers, contractors, and customers.<br />
          <br />
          At Gulf-Pro and throughout our supply chain, we prohibit harassment,
          discrimination, violence, and retaliation of any kind—and we have zero
          tolerance for violations motivated by any form of prejudice or
          bigotry. We require our employees to be trained annually on{" "}
          <Link to="/">Gulf-Pro’s Business Conduct Policy</Link>, which reflects
          our commitment to respect human rights and to conduct business
          ethically, honestly, and in compliance with applicable laws and
          regulations. <br />
          <br />We’re also deeply committed to the essential work of
          improving diversity, increasing inclusion, and advancing racial
          justice—both within our company which focuses on education and
          economic equality. Our efforts here are motivated by a strong desire
          to create a welcoming and supportive environment for all our teams and
          to help combat discrimination, injustice, and systemic racism. We
          require every Gulf-Pro employee to participate in trainings on
          unconscious bias, and we’re working to improve representation and
          diversity in positions of leadership and at every level of our
          company.
        </Typography>
      </Box>
    </div>
  );
}

export default HR;
