// Import the functions you need from the SDKs you need
import firebase, { initializeApp } from 'firebase/app';
import { getAuth  } from "firebase/auth";
import 'firebase/storage';
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcT8WFh0APU38-tSXi1uFrwujaSPrjkks",
  authDomain: "gps-policies.firebaseapp.com",
  projectId: "gps-policies",
  storageBucket: "gps-policies.appspot.com",
  messagingSenderId: "1007826130572",
  appId: "1:1007826130572:web:7ce0ee3f62b871239bcda9",
  measurementId: "G-HZ14BNDNVM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase(app);
const analytics = getAnalytics(app);

export { auth, db };
