import * as React from "react";
import "./App.css";
import { Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";

import SignIn from "./features/user/SignIn";

function App() {
  return (
    <div className="App">
      <Box
        sx={{
          maxWidth: 960,
          margin: "auto",
          textAlign: "left",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <SignIn />
        </Button>
        <Typography variant="h2" color="primary" sx={{ textAlign: "center" }}>
          Gulf-Pro Services Business Conduct
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Our Standard
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro conducts business ethically, honestly, and in full compliance
          with applicable laws and regulations. This applies to every business
          decision in every area of the company worldwide. The following
          principles guide Gulf-Pro’s business practices:
          <ul>
            <li>
              <strong>Honesty</strong>—Demonstrate honesty and high ethical
              standards in all business dealings.
            </li>
            <li>
              <strong>Respect</strong>—Treat customers, partners, suppliers,
              employees, and others with respect and courtesy.
            </li>
            <li>
              <strong>Confidentiality</strong>—Protect Gulf-Pro’s confidential
              information and the information of our customers, partners,
              suppliers, and employees.
            </li>
            <li>
              <strong>Compliance</strong>—Ensure that business decisions comply
              with applicable laws and regulations. Gulf-Pro expects its
              suppliers, contractors, consultants, and other business partners
              to follow these principles when providing goods and services to
              Gulf-Pro or acting on our behalf. Gulf-Pro also requires its
              suppliers to comply with the Gulf-Pro Supplier Code of Conduct.
            </li>
          </ul>
          The Business Conduct Policy applies to all full and part-time
          employees of Gulf-Pro, and provides a standard guide for what is
          required of everyone at Gulf-Pro. Relevant sections also apply to
          members of Gulf-Pro’s Executives. The Business Conduct Policy also
          provides information on additional resources available to employees,
          including the Business Conduct Helpline and the Business Conduct
          website, which contains guidance and frequently asked questions to
          assist employees in understanding Gulf-Pro’s approach to Business
          Conduct. All employees are required to complete annual, online
          Business Conduct training, and review and certify their understanding
          of the Business Conduct Policy. Employees are also required to
          complete online Respect at Gulf-Pro and Privacy trainings, and
          depending on job responsibilities and location, may be required to
          participate in additional mandatory online trainings on specific
          topics, such as anti-corruption. Focused live training is also
          arranged periodically on Business Conduct and other relevant topics.
          <br />
          On rare occasions, local laws may impose requirements on Gulf-Pro and
          its employees that differ from those set out in the Business Conduct
          Policy. Contact the Gulf-Pro main office for more information on how
          these laws may apply to you.
        </Typography>
        <Typography variant="h4">
          Your Responsibilities and Obligation to Take Action
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Everything we do is a reflection of Gulf-Pro. We expect you to:
          <ul>
            <li>
              <strong>Follow the Policy.</strong> Comply with the letter and
              spirit of Gulf-Pro’s Business Conduct Policy and all applicable
              legal requirements.
            </li>
            <li>
              <strong>Speak up.</strong> If you see or hear of any violation of
              Gulf-Pro’s Business Conduct Policy, other Gulf-Pro policies, or
              legal or regulatory requirements, you must notify either your
              manager or senior management
            </li>
            <li>
              <strong>Use good judgment and ask questions.</strong> Apply
              Gulf-Pro’s principles of business conduct, and review our policies
              and legal requirements. When in doubt about how to proceed,
              discuss it with your manager or senior management. The way we do
              business worldwide Gulf-Pro conducts business ethically, honestly,
              and in full compliance with applicable laws and regulations. This
              applies to every business decision in every area of the company
              worldwide. Any failure to comply with Gulf-Pro’s Business Conduct
              Policy—or failure to report a violation—may result in disciplinary
              action, up to and including termination of employment.
            </li>
          </ul>
          You are also required to fully cooperate in any Gulf-Pro
          investigation, and keep any information shared with you confidential
          to safeguard the integrity of the investigation.
        </Typography>
        <Typography variant="h4">Report a Concern</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          To report a concern or ask a question about Gulf-Pro’s Business
          Conduct Policy, you can contact management by phone, email, or web
          form. For contact details, visit the Business Conduct website or the
          Resources section at the end of this policy. Gulf-Pro’s external
          helpline (<Link to="/helpline">conduct.gulfpro.io/helpline</Link>)
          also allows employees and external parties to report concerns with the
          option of remaining anonymous, where permissible under applicable
          laws.
          <br />
          <br /> Your information will be shared only with those who have a need
          to know to help answer your questions or investigate concerns, ensure
          the prompt enforcement of this Policy, and, if appropriate, determine
          disciplinary action. A representative is available to support all
          employees and answer questions on business conduct issues, policies,
          regulations, and compliance with legal requirements.
        </Typography>
        <Typography variant="h4">No Retaliation</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro will not retaliate—and will not tolerate retaliation—against
          any individual for reporting a good-faith concern or complaint to a
          manager or senior management, or for participating in the
          investigation of a concern or complaint.
        </Typography>
        <Typography variant="h4">Your Rights as an Employee</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          You are permitted to speak freely about your wages, hours, and working
          conditions, including information about harassment, discrimination, or
          any other conduct you have reason to believe is unlawful, and nothing
          in this Policy, or any Gulf-Pro policy, should be interpreted as being
          restrictive of your right to do so.
        </Typography>
        <Typography variant="h4">Human Rights</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro is committed to respecting internationally recognized human
          rights. Gulf-Pro’s approach to respecting human rights is based on the
          United Nations Guiding Principles on Business and Human Rights, the
          global standard on business and human rights. For more information,
          see the <Link to="/human_rights">Human Rights Policy</Link>.
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Workplace Behaviors
        </Typography>
        <Typography variant="h4">Drugs and Alcohol</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro cares about the health and safety of our employees. You are
          expected to comply with Gulf-Pro’s guidelines regarding alcohol,
          drugs, and smoking, whether it is in the workplace, at
          Gulf-Pro-sponsored events, or while conducting Gulf-Pro business. You
          are not permitted to be under the influence of any legal or illegal
          drug that impairs your ability to perform your job, and employees are
          prohibited from manufacturing, soliciting, distributing, possessing,
          or using any illegal drugs or substances in the workplace, or while
          working. Use good judgment and keep in mind that you are expected to
          perform to your full ability at work. For more information, see the
          Drugs and Alcohol Policy.
        </Typography>
        <Typography variant="h4">Harassment and Discrimination</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro is dedicated to maintaining a creative, diverse, inclusive,
          and supportive work environment, and does not tolerate discrimination
          or harassment of employees or non-employees with whom we have a
          business, service, or professional relationship. This applies to all
          interactions where you represent Gulf-Pro, including interactions with
          employees, customers, suppliers, and applicants for employment. For
          more information, see the Equal Employment Opportunity Policy and
          People policies for your region. <br />
          <br />
          If you believe have been harassed or discriminated against, or have
          witnessed such behavior, we encourage you to report the incident using
          any avenue with which you feel most comfortable, including your
          Gulf-Pro manager, HR, or top management. <br />
          <br />
          We also do not tolerate workplace violence of any kind.
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Individual Accountability
        </Typography>
        <Typography variant="h4">Avoiding Conflicts of Interest</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          A conflict of interest is any activity that may damage Gulf-Pro’s
          reputation or financial interests, or gives the appearance of
          impropriety or divided loyalty. Avoid any situation that creates a
          real or perceived conflict of interest. If you are unsure about a
          potential conflict, talk to your manager, or senior managment. <br />
          The following are common situations employees may encounter that could
          present a conflict of interest.
        </Typography>
        <ul>
          <li>
            <Typography variant="h6" style={{ textAlign: "left" }}>
              Significant Personal Relationships
            </Typography>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: 16 }}
            >
              Personal relationships in the workplace can present a real or
              perceived conflict of interest when one individual in the
              relationship makes or influences employment decisions regarding
              the other, including performance or compensation. Significant
              personal relationships include, but are not limited to, spouses,
              domestic partners, family members, dating or physical
              relationships, close friends, and business relationships outside
              of Gulf-Pro. Gulf-Pro business relationships include, but are not
              limited to, vendors, customers, suppliers, contractors, temporary
              agency workers, or similar relationships. Do not conduct Gulf-Pro
              business with family members or others with whom you have
              a significant personal relationship. Do not use your position at
              Gulf-Pro to obtain favored treatment for yourself, family members,
              or others with whom you have a personal relationship. This applies
              to product purchases or sales, investment opportunities, hiring,
              promoting, selecting contractors or suppliers, and any other
              business matter. If you believe that you have a potential conflict
              involving a family member or other individual, disclose it to your
              manager and HR to review and work through any potential conflicts.
              You should not allow any relationship to disrupt the workplace or
              interfere with your work or judgment. In rare cases where
              exceptions may be appropriate, written approval from senior
              management of your organization is required.
            </Typography>
          </li>
          <li>
            <Typography variant="h6" style={{ textAlign: "left" }}>
              Outside Activities
            </Typography>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: 16 }}
            >
              Gulf-Pro generally considers an outside activity to be a conflict
              of interest if it:
              <ul>
                <li>
                  Is for a company or organization that makes, sells, or rents
                  competing products or services to Gulf-Pro, or that Gulf-Pro
                  is reasonably anticipated to rent.
                </li>
                <li>
                  Generates or exposes you to intellectual property that
                  competes with or relates to Gulf-Pro’s present or reasonably
                  anticipated business, products, or services.
                </li>
                <li>
                  Would require you to disclose or use confidential Gulf-Pro
                  information.
                </li>
                <li>Is the same work you do for Gulf-Pro.</li>
                <li>
                  Arises from your role in Gulf-Pro’s business relationship with
                  the organization.
                </li>
              </ul>
              If an outside activity presents a conflict of interest there must
              be written approval from senior management to accept or continue.
              <br />
              Any employee, full or part-time, who is participating in an
              outside activity, must comply with the following rules.
              <br />
              <strong>Do not:</strong>
              <ul>
                <li>
                  {" "}
                  Use any time at work or any Gulf-Pro assets for your outside
                  activity. This includes Gulf-Pro’s workspace, phones,
                  computers, Internet access, photocopiers, and any other
                  Gulf-Pro assets or services.
                </li>
                <li>
                  Use your position at Gulf-Pro to solicit resources or any
                  other benefit for your outside activity, obtain favored
                  treatment, or pressure others to assist you.
                </li>
                <li>
                  Participate in an activity that could have an adverse effect
                  on your ability to perform your duties at Gulf-Pro.
                </li>
                <li>Use confidential Gulf-Pro information.</li>
              </ul>
            </Typography>
          </li>
        </ul>
        <Typography variant="h4">Gifts</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Giving or accepting business gifts can create a real or perceived
          conflict of interest and can lead to a perception of favoritism and an
          expectation of reciprocity that could compromise an employee’s
          objectivity, even inadvertently.{" "}
          <strong>
            Gulf-Pro employees are under either a zero gift rule or a $150 gift
            rule,
          </strong>{" "}
          depending on their organization. Employees under a zero gift rule may
          not give or accept gifts to or from current or potential vendors,
          suppliers, customers, or other business associates, regardless of the
          value, unless one of the key exceptions below applies. Employees under
          the $150 gift rule may only give or accept gifts if the value is under
          $150, unless one of the key exceptions below applies. Gifts must not
          reflect poorly on Gulf-Pro if publicly disclosed, and must be legal in
          the location and under the circumstances where given. Gifts given with
          the purpose of influencing a decision are always prohibited. <br />
          <br />A gift is considered anything of value, including a meal,
          travel, entertainment (including tickets), Gulf-Pro logo items,
          equipment or loans, and employee discounts. Gifts that are cash or
          cash equivalents, such as gift cards, are never allowed. In addition,
          paying for a gift without getting reimbursement from Gulf-Pro does not
          remove the requirement to comply with the gift policy. Gifts between
          employees are not considered business gifts.
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Business Integrity
        </Typography>
        <Typography variant="h4">Governments as Customers</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Governments often have unique bidding, pricing, disclosure, and
          certification requirements. When dealing with government customers,
          make sure to contact senior management when bidding for business,
          and/or with questions relating to compliance requirements.
        </Typography>
        <Typography variant="h4">Bribery and Corruption</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          At Gulf-Pro, we do not tolerate corruption in connection with any of
          our business dealings. Corruption can take many forms, but most often
          it occurs through bribery. A bribe is offering or giving anything of
          value, including cash, cash equivalents such as gift cards, gifts,
          meals, travel and entertainment, to any person for the purpose of
          obtaining or retaining business, or securing an improper advantage.
          You cannot offer or receive bribes from any individual, regardless of
          whether that individual is a public official or a private party.
          Kickbacks are a type of bribery, and occur when a person is offered
          money or something of value in exchange for providing something, such
          as information, a discount or a favor, to a third party. Kickbacks are
          not permissible and are strictly prohibited by Gulf-Pro. <br />
          <br />
          Facilitating payments are a type of bribe generally used to facilitate
          or expedite the performance of routine, nondiscretionary government
          action. These payments are not permissible and are strictly prohibited
          by Gulf-Pro. Exceptions may be made in circumstances that involve an
          imminent threat to health or safety, and such situations must be
          immediately reported to senior management.
          <br />
          <br /> Gulf-Pro can be found responsible for bribes, kickbacks, and/or
          facilitating payments made by third parties in connection with
          Gulf-Pro’s business. Before engaging a third party that will be
          interacting with the government or public officials on Gulf-Pro’s
          behalf, contact senior management to evaluate whether we need to
          conduct additional due diligence.
        </Typography>
        <Typography variant="h4">Private Employee Information</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          You should never share a coworker or prospective employee’s personal
          information. This includes information regarding their employment
          history, personal contact information, compensation, health
          information, or performance and disciplinary matters. Any Legal or
          business need-to-know exceptions should be approved by your manager.
          <br />
          <br />
          As an Gulf-Pro employee, you should understand that subject to local
          laws and regulations and in accordance with Gulf-Pro’s review process,
          we may do one of the following when you access Gulf-Pro’s network or
          systems, or use any device, regardless of ownership, to conduct
          Gulf-Pro business:
          <ul>
            <li>
              Access, search, monitor, and archive all data and messages sent,
              accessed, viewed, or stored (including those from personal
              accounts).
            </li>
            <li>
              {" "}
              Conduct physical, video, or electronic surveillance, search your
              workspace (e.g. file cabinets, desk drawers, and offices, even if
              locked), review phone records, or search any non-Gulf-Pro property
              (e.g. backpacks, handbags) while on company premises.
            </li>
            <li>
              Disclose to law enforcement, without prior notice, any information
              discovered during a search that may indicate unlawful behavior{" "}
            </li>
          </ul>
          While limited personal use of Gulf-Pro equipment and systems is
          allowed, Gulf-Pro may monitor equipment and systems. You should not
          have any expectation about the privacy of content or personal
          information on Gulf-Pro systems or networks, including VPN.
        </Typography>
        <Typography variant="h4">Human Trafficking / Modern Slavery</Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "left", marginBottom: 16 }}
        >
          Gulf-Pro is committed to treating everyone in our business and supply
          chain with dignity and respect, to upholding human rights across our
          network of suppliers, and to protecting the planet we all share. Human
          trafficking and the use of involuntary labor are strictly prohibited
          in Gulf-Pro’s supply chain and our own business operations. If you
          become aware of human trafficking or behavior supporting human
          trafficking, you must report this activity to Business Conduct as soon
          as possible. Any Gulf-Pro employees who interact with the U.S.
          government may be required to abide by additional requirements set for
          government contractors.
        </Typography>
      </Box>
    </div>
  );
}

export default App;
