import * as React from "react";
import { Typography, Button, Box, TextField, Alert, Collapse  } from "@mui/material";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { push, child, ref } from 'firebase/database';
import { db } from "../firebase_config";

function HelpLine() {
    const [input, setInput] = React.useState('');
    const [submitted, setSubmitted] = React.useState(false);
    
    const handleChange = e => {
        setInput(e.target.value);
    }
    const submitForm = e => {
        e.preventDefault();
        const dbRef = ref(db);
        const feedback = (child(dbRef, 'feedback'));
        push(feedback, (input)).then(() => {
          setInput('');
          handleAlert();
          });
    }
    
    const handleAlert = () => {
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 4000);
    }
    return (
    <div className="help">
      <Box
        sx={{
          maxWidth: 960,
          margin: "auto",
          textAlign: "left",
        }}
      >
        <Typography variant="h2" color="primary" sx={{ textAlign: "center" }}>
          Gulf-Pro Business Conduct Helpline
        </Typography>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "gray", marginTop: 1 }}
        >
          Fill out form below to share feedback on policy observations
        </Typography>
        <form onSubmit={submitForm}>
            <TextField
                value={input}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                label="Concern"
                placeholder="Please describe your concern..."
            />
            <Collapse in={submitted}>
              <Alert severity="success" icon={<RecordVoiceOverIcon  fontSize="inherit" />}>
                Thank you for your submission!
              </Alert>
            </Collapse>
        <Button type="submit">Submit</Button>
        <Typography variant="h5" sx={{ textAlign: "center"}}>
            Helpline 1-985-240-5328
            <br/>
            (Call or Text)
        </Typography>
        </form>
    </Box>
    </div>
    )
}

export default HelpLine;
