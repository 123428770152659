import React, { useRef, useState } from 'react'
import { 
    Dialog,
    Button,
    TextField
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

// importing from our Firebase Config file.
import { auth } from './../../firebase_config';


// Importing functions from Firebase Authentication
import { signInWithEmailAndPassword  } from "firebase/auth";


// Creates a new User 
function SignIn() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  // Sign In using Email and Password
  const signIn = (e) => { 
    e.preventDefault();

    signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
    .then((authUser)=>{
      console.log(authUser);
    }).catch(error=>{
      alert(error.message)
    });

}

const toggle = () => setOpen(prev => !prev);
  return (
    <>
    <Dialog open={open} onClose={toggle} >
    
      {JSON.stringify(user)}
      <form style={{padding: 16}}>
        <h1>Sign In</h1>
        <TextField fullWidth ref={emailRef} placeholder='Email' type="input"/>
        <TextField fullWidth ref={passwordRef} placeholder='Password' type="password"/>
        <Button type='submit' onClick={signIn}>Sign In</Button>
      </form>
    </Dialog>
    <Button onClick={toggle}>{user ? 'Signed In' : "Sign In"}</Button>
    </>
  )
}

export default SignIn;